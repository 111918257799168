import React, { useEffect, useState } from 'react';
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';


const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
              Hi, my name is Suad Ali Hersi. I'm a recent Computer Science graduate with a passion for problem-solving and technology. 
              I'm excited to apply my knowledge and skills in real-world scenarios.
               My experience ranges from coding projects to collaborative team work, all backed by the solid foundation of my bachelor's degree.

            </p>

          <div className="summary">
          <h2>Services i can offer</h2>
          As a fresh Computer Science graduate, I could offer services such as:
<ul>
<li>Software development: Building or updating software applications using your coding skills.</li> 
<li> Web development: Creating and managing websites or web applications. </li> 
<li> Data analysis: Using programming to analyze and interpret complex data.</li> 
<li> IT consulting: Helping businesses optimize their technology use.</li> 
<li> Teaching or tutoring: Sharing my knowledge with others who want to learn about computer science.</li> 
</ul>
</div>
</div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default About