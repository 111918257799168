import React, { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import LogoTitle from '../../assets/images/logo-s.png';
import Suadd from '../../assets/images/Suadd.png';
import './index.scss';

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const jobArray = ['Status: On Gap Year'];
  const location = ['Based : London, England'];
  const nameArray = ['uad Ali Hersi'];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1 className={letterClass}>
            <span className="greeting">Hi I'm</span>
            <img src={LogoTitle} alt="Logo" className="logo" />
            <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={22} />
            <br />
            <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />
            <br />
            <AnimatedLetters letterClass={letterClass} strArray={location} idx={22} />
          </h1>
        </div>
    
      </div>

      <Loader type="pacman" />
    </>
  );
};

export default Home;
