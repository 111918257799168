import {Link, NavLink} from 'react-router-dom'
import './index.scss'
import { useState } from 'react'
import LogoS from '../../assets/images/logo-s.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import logosuad from '../../assets/images/logosuad.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faHome, faUser, faSuitcase,faBars,faClose, } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin,faGithub,faYoutube,faSkype} from '@fortawesome/free-brands-svg-icons'
  
const Sidebar= () => {
    const [showNav, setShowNav] = useState(false);
    return(
        <div className="nav-bar">
        <Link 
            className="logo" 
            to ="/" 
            onClick={() => setShowNav(false)}>
            <img src={LogoS} alt="logo"/>
            <img className="sub-logo" src={logosuad} alt="suad"/>
        </Link>
        <nav className={showNav ? 'mobile-show' : ''}>
            <NavLink    
                exact="true" 
                activeclassname="active"
                 to="/"
                 onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon ={faHome} color="#4d4d4e" />
            </NavLink>
            <NavLink 
            exact="true" 
            activeclassname="active" 
            className="about-link" 
            to="/about"
            onClick={() => setShowNav(false)}>
            <FontAwesomeIcon 
            icon ={faUser} color="#4d4d4e" />
            </NavLink>
           
            <NavLink 
            exact="true"    
            activeclassname="active" 
            className="contact-link"
            to="/contact"
            onClick={() => setShowNav(false)}>
            <FontAwesomeIcon icon ={faEnvelope} color="#4d4d4e" />
            </NavLink>
            <FontAwesomeIcon 
            onClick={() => setShowNav(false)}
            icon={faClose}
             color="#ffd700"
            size="3x"
            className='close-icon' />
        </nav>
        <ul>
            <li>
          <a
            href="https://www.linkedin.com/in/suad-hersi9/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>
        </li>
        <li>
          <a
            href="https://github.com/suadcodes"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faGithub}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>
        </li>
        
    
      </ul>
      <FontAwesomeIcon 
          onClick={() => setShowNav(true)}
          icon={faBars}
          color="#ffd700"
          size="3x"
          className='hamburger-icon' />
</div>

)
}
export default Sidebar