import { useEffect, useState, useRef } from 'react';
import Loader from 'react-loaders';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import emailjs from '@emailjs/browser';
import AnimatedLetters from '../AnimatedLetters';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import './index.scss';
import red from '../../assets/images/red.png'
import L from 'leaflet';
const customMarkerIcon = L.icon({
  iconUrl: red,
  iconSize: [48, 48], // Size of the icon
  iconAnchor: [24, 48], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -48], // Point from which the popup should open relative to the iconAnchor
});

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const form = useRef();
  useEffect(() => {
    emailjs.init('IJBajgA6ToCoyY5HE');
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
    return () => clearTimeout(timer); // Cleanup the timeout on component unmount
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("suadah473@gmail.com","template_wowepmu", e.target)
      .then(
        () => {
          alert('Message successfully sent!');
          window.location.reload(false);
        },
        () => {
          alert('Failed to send the message, please try again');
        }
      );
  };

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I'm open to opportunities - especially on projects. However, if you have any other requests or
            questions, don't hesitate to contact me using below form either.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          London, United Kingdom <br />
          <br />
          <span>suadah473@gmail.com</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[51.507351, -0.127758]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[51.507351, -0.127758]} icon={customMarkerIcon}>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Contact;
